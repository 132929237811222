main {
  min-height: 80vh;
}

/* HEADER STYLES */
.offcanvas.offcanvas-end {
  width: 200px !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.container {
  /* margin-top: 30vh; */
  /* align-items: center; */
  /* d-flex justify-content-center */
  /* padding-left: 0 !important; */
}

.card-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 30vh; */
  /* margin-top: 10vh; */
}

/* @media(max-width: 768px) {
  main {
    display: flex;
    align-items: center;
  }
} */

.backLink {
  text-decoration: none;
  color: black;
  text-transform: capitalize;
  cursor: pointer;
  border: 1px solid black;
  padding: .5rem;
  border-radius: .5rem;
}

.backLink:hover {
  color: white;
  background-color: black;
}



/* WORD CARD */
.card-container {
  display: flex;
  justify-content: center;
  border: none;
}

.card_flipper {
  position: relative;
}

/* WORD W/ ANSWER BUTTONS */
.card_flipper .answer {
  transform: rotateY(90deg);
  position: absolute;
}

.flipped .answer {
  transform: rotateY(0deg);
}

/* HAD TO UPDATE THIS TO ONLY EFFECT WORD CARDS */
.card.cards {
  border: none !important;
}

.cards {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 20rem;
  transition: transform 0.5s;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
}

.cards .front,
.cards .back {
  display: flex;
  flex-direction: column;
  backface-visibility: hidden;
  position: absolute;
  width: 20rem;
  height: 5rem;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 1rem;

  transition: transform 0.2s, opacity 0.2s;
  opacity: 1;
}

.cards .front:hover,
.cards .back:hover {
  /* translate: -2px; */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}

.cards .back {
  transform: rotateY(180deg);
  opacity: 0;
}

.cards.flip {
  transform: rotateY(180deg);
}

.cards.flip .front {
  opacity: 0;
  /* transition-delay: 0.25s; */
}

.cards.flip .back {
  opacity: 1;
  /* transition-delay: 0.25s; */
}

.pronunciation-text {
  font-size: 12px;
  color: rgb(150, 150, 150);
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(5);
  }
  100% {
    transform: scale(1);
  }
}

.animated-star {
  animation: scaleAnimation .5s ease-in-out;
}

/* WORD PAGE */



/* SCORE */
.score span {
  margin: 0.1rem;
  transition: transform 0.8s;
  /* transform-style: preserve-3d; */
}

h3 {
  padding: 1rem 0;
}

/* LANGUAGE LIST CAROUSEL */
/* .container {
  width: 80%;
  margin: auto;
} */

.language-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  /* width: 63rem;
  height: 22rem; */
  margin: auto;
}

.language-card-container {
  position: relative;
  width: 63rem;
  height: 12rem;
  /* margin-right: 7rem; */
}

.language {
  position: absolute !important;
  width: 15rem !important;
  height: 10rem !important;
  margin: 1rem;
  border-radius: 1rem;
  text-align: center;
  text-transform: capitalize;
  justify-content: center;
  align-items: center;
}

.card-img.flag {
  opacity: 40%;
}

.card-title {
  font-weight: bolder !important;
  padding-top: 3rem;
  opacity: 100%;
}

.flag.card-img {
  height: 100% !important;
}

.card-link.btn {
  padding: .3rem;
  box-shadow: none;
}

.card-link.btn:hover {
  border: .5px solid black;
  /* background-color: rgba(116, 116, 116, 0.5); */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

}

.prevCard {
  left: 6%;
  /* left: -200%; */
  /* opacity: 50%; */
  /* z-index: 2;
  background: linear-gradient(to top, #222, #222); */
  opacity: 0;
}

/* .prevCard + .prevCard {
  opacity: 100; 

}
 */


.activeCard {
  left: 50%;
  transform: translateX(-50%);
  /* THE CARD IS CENTERED TO ITSELF RATHER THAN THE LEFT EDGE RESTING ON CENTER LINE */
  z-index: 1;
}

/* .activeCard .flag {
  opacity: 100%;
} */

.nextCard {
  /* right: 0; */
  right: 3%;
  /* right: 200%; */
  /* opacity: 50%; */
  opacity: 0%;
}

.activeCard + .nextCard {
  z-index: 1;
  /* opacity: 0; */
}

.leftBtn,
.rightBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* .rightBtn {
  display: flex;
  align-items: center;
} */


.link-disabled {
  pointer-events: none;
  /* text-decoration: none; */
  border: 1px solid black !important;
}

/* MAP */
.rsm-geography {
  /* position: absolute;
  width: 100%;
  height: 100%; */
  /* transition: 0.5s all ease-in-out; */
  transition: all .2s ease-in-out;
  transform-origin: center;
  /* transform-origin: 50% 50%; */
  /* width: 100%; */
  /* transition: width .5s; */
  /* transform-origin: top left;
  transition: .5s ease-in-out; */
  /* translate: none; */
  /* transform: scale(2, 2) .5s ease-in-out; */
}

.rsm-geography:hover {
  transform: scale(1.01);
}

.country-hover {
  display: inline-grid;
  justify-items: center;
  position: absolute;
  z-index: 5;
  /* ADD MEDIA QUERIES FOR top/left STYLNG AT VARIOUS SCREEN SIZES */
  top: 70vh;
  left: 45vw;
}

.carousel-container {
  position: absolute;
  justify-items: center;
  justify-content: center;
  bottom: 10%;
}

/* REGISTER SCREEN STYLES */

.invalid_select .react-select__control {
  border-color: red !important;
}

.invalid_select .react-select__value-container {
  /* background-color: red !important; */
  /* padding-right: calc(1.5rem + 1rem); */
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23da292e%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23da292e%27 stroke=%27none%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375rem + .25rem) center;
  background-size: calc(.75rem + .5rem) calc(.75rem + .5rem);
}

/* PROFILE PAGE */
ul.nav-tabs li:last-child {
  margin-left: auto;
}

.move-word-btn {
  cursor: pointer;
}

/* FOOTER */
footer {
  opacity: 50%;
}

.copyright {
  font-size: 12px;
}

.bug-btn {
  cursor: pointer;
  /* font-size: 12px; */
  /* color: green; */
  /* opacity: 50%; */
}

ul.nested-tabs li:last-child {
  /* justify-content: left !important; */
  margin-left: 0 !important;
}